.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #1345CA;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.loadingSpinner {
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  filter: brightness(0) invert(1); /* Hace la imagen blanca */
}

.loadingText {
  margin-top: 16px;
  font-size: 1.2rem;
  color: #F5F5F5;
  font-weight: 500;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 