.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  transition: all 0.15s ease;
  white-space: nowrap;
}

.default {
  background-color: #1345CA;
  color: #F5F5F5;
  border: 1px solid transparent;
}

.secondary {
  background-color: var(--colorGrey100);
  color: var(--colorGrey700);
  border: 1px solid transparent;
}

.destructive {
  background-color: #ABCAEE;
  color: #1345CA;
  border: 1px solid transparent;
}

.outline {
  background-color: transparent;
  color: var(--colorGrey700);
  border: 1px solid var(--colorGrey100);
}