@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.featuredCard {
  position: relative;
  background: white;
  border-radius: calc(var(--borderRadiusMedium) + 3px);
  padding: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 3px;
    background: linear-gradient(135deg, #ffd700 0%, #b8860b 100%);
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    z-index: 1;
  }

  .aspectRatioWrapper {
    z-index: 0;
    box-shadow: 0 2px 10px rgba(255, 215, 0, 0.3);
    border-radius: calc(var(--borderRadiusMedium) - 1px);
    overflow: hidden;
    
    /* Remove the transformation of the aspectRatioWrapper since now the entire card will be transformed */
    @media (--viewportMedium) {
      &:hover {
        transform: none;
      }
    }
  }

  .info {
    padding: 14px 8px 2px 8px;
  }

  .priceValue {
    color: #442c00;
  }

  .perUnit {
    color: #442c00;
  }

  .authorInfo {
    color: var(--colorGrey700);
  }

  .title {
    color: var(--colorGrey700);
  }
}

.featuredBadge {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  padding: 4px 12px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 12px;
  background: linear-gradient(135deg, #ffd700 0%, #b8860b 100%);
  color: #442c00;
  box-shadow: 0 2px 10px rgba(255, 215, 0, 0.3);
}

.aspectRatioWrapper {
  position: relative;
  z-index: 1;
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: none;
  border-radius: calc(var(--borderRadiusMedium) - 1px);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);
  font-size: 20px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
