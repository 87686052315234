.inputWrapper {
  position: relative;
}
.birthdayField {
  margin-bottom: 24px;
}
.dateInput {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  background-color: white;
  color: #4a4a4a;
}
.extraInfo {
  composes: textSmall from global;
  color: var(--colorGrey300);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.dateInput:focus {
  outline: none;
  
}

.dateInput::placeholder {
  color: #c3c3c3;
}

.dateInput.error {
  border-color: #e53935;
}

.calendarIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #4a4a4a;
}

.errorMessage {
  color: #FF0000;
  font-size: 13px;
  font-weight: 700;
  margin-top: 10px;
  display: block;
}
/* .inputWrapper {
  position: relative;
}

.dateInput {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  background-color: white;
  color: #4a4a4a;
}

.dateInput:focus {
  outline: none;
  border-color: #4a4a4a;
}

.dateInput::placeholder {
  color: #c3c3c3;
}

.calendarIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #4a4a4a;
} */